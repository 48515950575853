import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from '../Image';

const query = graphql`
  query {
    cultureAppShowcase: file(
      relativePath: { eq: "culture-biosciences-app-showcase.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const CultureBiosciencesAppShowcase = props => (
  <StaticQuery
    query={query}
    render={data => (
      <Image fluid={data.cultureAppShowcase.childImageSharp.fluid} {...props} />
    )}
  />
);

export default CultureBiosciencesAppShowcase;
