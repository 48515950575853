import React from 'react';
import { css } from 'emotion';

import Base from '../../components/Base';
import SEO from '../../components/SEO';
import AlternateBannerSection from '../../components/sections/AlternateBannerSection';
import PageSection from '../../components/layout/PageSection';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import CTASection from '../../components/sections/CTASection';

import { colors } from '../../constants/colors';
import { smallScreen } from '../../constants/breakpoints';
import Strings from '../../constants/strings';

// Images and Assets
import CultureBiosciencesAppShowcaseImage from '../../components/queriedImages/CultureBiosciencesAppShowcaseImage';
import CultureBiosciencesDeveloperExperienceImage from '../../components/queriedImages/CultureBiosciencesDeveloperExperienceImage';
import CultureBiosciencesGraphQLToolingImage from '../../components/queriedImages/CultureBiosciencesGraphQLToolingImage';
import CultureBiosciencesCasePreviewImage from '../../components/queriedImages/CultureBiosciencesCasePreviewImage';

const CultureBiosciencesCaseStudy = () => (
  <Base>
    <SEO title="Culture Biosciences case study" />
    <AlternateBannerSection
      title={Strings.CULTURE_BIOSCIENCES_TITLE}
      subtitle={Strings.CULTURE_BIOSCIENCES_SUBTITLE}
    />

    {/* Project Overview Section */}
    <PageSection>
      <ResponsiveContainer>
        <div className={cn.base}>
          <div className={cn.sectionInfo}>
            <h3>Project Overview</h3>
            <p>
              Culture Biosciences was launching a new product and using GraphQL
              for the first time. They wanted to ensure their new GraphQL
              backend and schema would be built with best practices and poised to
              scale. On the front-end, it was important to have a robust
              foundation, optimized for developer experience, with important
              patterns like state management and routing established and
              implemented.
            </p>
            <p>
              At Drover, we understand how important a strong foundation is for
              startups launching a new product. We've seen firsthand and
              repeatedly heard stories of foundational sprints pushing actual
              product development months down the roadmap. Our task was to use
              our experience to create the product and the foundational patterns
              all in one go, saving Culture Biosciences months of dev time and
              allowing their new hires to hit the ground running without the
              overhead of starting from scratch.
            </p>
            <p>
              Over the course of six weeks, we provided GraphQL support and
              expertise for the back end developers, and built a beautiful front
              end that new developers could quickly become productive in. We
              ended up with a quality GraphQL schema, a complete front end
              application, and robust accompanying documentation and developer
              tooling.
            </p>
          </div>
          <div className={cn.sectionImage}>
            <CultureBiosciencesCasePreviewImage />
          </div>
        </div>
      </ResponsiveContainer>
    </PageSection>

    {/* App Showcase Section */}
    <PageSection className={cn.showcase} dark>
      <CultureBiosciencesAppShowcaseImage
        objFit="contain"
        objPosition="50% 50%"
        style={{ maxHeight: '750px' }}
        imgStyle={{ maxHeight: '750px' }}
      />
    </PageSection>

    {/* GraphQL Expertise Section */}
    <PageSection>
      <ResponsiveContainer>
        <div className={cn.base}>
          <div className={cn.sectionInfo}>
            <h3>GraphQL Expertise</h3>
            <p>
              We held a schema design workshop to kick off the project,
              collaboratively modeling the core business entities into a GraphQL
              schema.
            </p>
            <p>
              We provided advice and expertise on GraphQL best practices by
              providing thorough code reviews on schema change pull requests.
            </p>
            <p>
              We set up GraphQL tooling that we have found to be helpful for
              developers in the past, including schema-based typescript type
              generation and use of the schema to create a mock server for local
              development. We established front end patterns for maintainable
              queries, mutations, and fragments.
            </p>
          </div>
          <div className={cn.sectionImage}>
            <CultureBiosciencesGraphQLToolingImage />
          </div>
        </div>
      </ResponsiveContainer>
    </PageSection>

    {/* UI Foundation Section */}
    <PageSection dark>
      <ResponsiveContainer>
        <div className={cn.base}>
          <div className={cn.sectionImage}>
            <CultureBiosciencesDeveloperExperienceImage />
          </div>
          <div className={cn.sectionInfo}>
            <h3>UI Foundations</h3>
            <p>
              We built out the initial features of the application, with a focus
              on making the codebase simple, maintainable and without
              unnecessary dependencies. We made decisions on major patterns like
              routing and state management, implementing full features and
              providing supporting documentation.
            </p>
            <p>
              We took care to focus on the developer experience. By heavily
              documenting the tools used and taking extra steps to create
              building blocks for future developers, new members could be
              onboarded more easily to the codebase. An example of this was
              documenting core components in Storybook.
            </p>
            <p>
              In addition to creating a strong base, we established patterns for
              flourishes like skeleton views and screen transitions.
            </p>
          </div>
        </div>
      </ResponsiveContainer>
    </PageSection>

    <CTASection
      title="Ready to build the foundation for your next project?"
      ctaText="Let's get started"
    />
  </Base>
);

const cn = {
  base: css`
    display: flex;
    justify-content: space-between;

    flex-flow: column nowrap;
    @media (min-width: ${smallScreen}) {
      flex-flow: row nowrap;
      align-items: center;
    }
  `,
  sectionInfo: css`
    flex: 0 0 45%;
    margin-bottom: 2.5rem;
  `,
  sectionImage: css`
    flex: 0 0 45%;
    margin-bottom: 2.5rem;
  `,
  showcase: css`
    padding: 0 0 0 0;
    background: radial-gradient(750px at 50% 50%, #303b64 0%, #000027 100%);
    border-top: 1rem solid ${colors.lightText};
    border-bottom: 1rem solid ${colors.lightText};
    border-top-left-radius: 40%;
    border-bottom-right-radius: 40%;
  `,
};

export default CultureBiosciencesCaseStudy;
